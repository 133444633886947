@import "assets/styles/material-dashboard.scss";

form .form-group select.form-control {
  position: inherit !important;

  &.multiple {
    margin-top: 10px !important;
    height: 90px !important;
  }
}

/* General */
h1 {
  font-size: 2rem;
  padding: 0 15px;
  margin-bottom: 15px;
}

.main-panel {
  .content {
    padding: 12px 15px;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

/* Material icons custom colors */
.material-icons {
  &.red {
    color: #f44336;
  }

  &.green {
    color: #00c853;
  }
}

/* Help */
.ppp-help {
  transform: scale(0.8);
  color: #d5135b;
  padding: 0;

  &:focus,
  &:hover,
  &:active {
    color: #d5135b;
  }
}

/* Pagination system */
.ppp-pagination {
  margin-top: 1rem;

  .page-item.active>a {
    background-color: #d5135b;
    border-color: #d5135b;
    color: #fff !important;

    &:focus,
    &:hover,
    &:active {
      background-color: #d5135b;
      border-color: #d5135b;
    }
  }

  li {
    a {
      color: #666 !important;

      &:focus,
      &:hover,
      &:active {
        color: #111 !important;
      }
    }
  }
}

/* Login pages */
.login-page {
  .ppp-footer {
    padding: 26px 0;
    border-top: 8px solid #d5135b !important;
    background-color: #06172c !important;

    .copyright {
      color: #fff !important;
    }

    &:after {
      position: absolute;
      content: '';
      z-index: 0;
      width: 100%;
      bottom: 0;
      height: 40px;
      background-image: linear-gradient(#06172c, #020810);
    }
  }

}

/* Datalist */
.custom-datalist {
  position: relative;

  .form-group {
    margin-top: 0 !important;

    input {
      background-color: #fff;
    }

    .bmd-label-floating {
      font-weight: 400;
      color: #000 !important;
    }
  }

  ul {
    position: absolute;
    display: block;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    max-height: 273px;
    min-width: 100%;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    background-color: white;
    z-index: 10000;

    li {
      position: relative;
      width: auto;
      display: block;
      flex-flow: nowrap;
      align-items: center;
      color: #333;
      font-weight: normal;
      text-decoration: none;
      font-size: 0.8125rem;
      border-radius: 0.125rem;
      margin: 0;
      transition: all 150ms linear;
      min-width: 7rem;
      padding: 0.625rem 1.25rem;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      overflow: hidden;
      line-height: 1.42857143;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: nowrap;
      text-align: left;

      &.loading-item {
        padding-top: 1.25rem;

        &:hover {
          cursor: initial;
          background-color: transparent;
        }
      }
    }

    li:hover {
      cursor: pointer;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
      background-color: #004093;
      color: #FFFFFF;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}

/* Left sidebar */
body.sidebar-mini {
  .ppp-navigation {
    .nav {
      .nav-item {
        .nav-link {
          margin: 0 15px !important;
        }
      }
    }
  }
}

/* Misc */
.description {
  color: #d5135b;

  &.promoCode {
    font-size: 1.25rem;
  }
}

/* Cards */
.card {
  .card-body {
    padding: 15px;
  }
}

.card:hover [data-header-animation=true] {
  transform: translate3d(0, -12px, 0);
}

/* Datatable */
.dataTables_empty,
.dataTables_info {
  display: none;
}

.dataTables_filter {
  text-align: right !important;

  label {
    color: #555;

    .form-control,
    .is-focused .form-control {
      background-image: linear-gradient(to top, #004093 2px, rgba(156, 39, 176, 0) 2px),
        linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
    }
  }
}

/* Tables */
.card {
  .card-body {
    .table-responsive {
      margin-top: 15px;

      .ngx-datatable {
        overflow-x: scroll;
        overflow-y: auto;
        transform: rotateX(180deg);

        .visible {
          width: 100%;
          transform: rotateX(180deg);
        }

        .datatable-scroll {
          width: 100% !important;
        }

        .datatable-header {
          overflow: inherit;
          border-bottom: 0;

          .datatable-header-cell {
            padding: 1px 8px;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1.05rem;
            color: #333;
          }
        }

        .datatable-body {
          .datatable-body-cell {
            padding: 8px;
            line-height: 1;
            white-space: pre-wrap;
            font-weight: inherit;
            border: 1px solid #aaa;

            .ppp-link {
              color: #004093;
              font-weight: 400;

              &:focus,
              &:hover,
              &:active {
                color: #111;
              }
            }
          }

          .datatable-body-row {
            &:hover {
              background-color: transparent;
            }
          }

          .datatable-row-group.datatable-row-center {
            &:hover {
              background-color: transparent;
            }

            .datatable-body-cell:last-child {

              a,
              button {
                padding: 5px;
                margin: 3px 5px 3px 0;
                background-color: #004093;
              }

              .btn-danger {
                background-color: #f44336;
              }
            }
          }
        }
      }
    }
  }
}

/* Table personnalisée pour le module "Suivi d'activité TC4" */
.card {
  .card-body {
    .table-responsive {
      .ngx-datatable.activityDashboard {
        .datatable-header {
          .datatable-header-cell {
            font-size: 1rem;
            text-transform: initial;
            border: 1px solid #aaa;
          }
        }

        .datatable-footer {
          .datatable-footer-inner {
            display: none;
          }
        }
      }
    }
  }
}

/* Radio buttons */
.form-check {
  .form-check-input:checked~.circle {
    border-color: #d5135b;
  }

  .form-check-label {
    .circle {
      .check {
        background-color: #d5135b;
      }
    }
  }
}

/* Checkboxes */
.form-check {
  .form-check-input:checked~.form-check-sign {
    .check {
      background-color: #d5135b;
    }
  }
}

/* Breadcrumb */
.breadcrumb {
  background-color: transparent;
  padding: 0 0 5px 15px;
  margin-bottom: 0;

  &-item {
    font-size: 0.75rem;

    a {
      color: #0b1d32;
      font-weight: 300;

      &:focus,
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    &.active {
      a {
        font-weight: 400;

        &:focus,
        &:hover,
        &:active {
          text-decoration: inherit;
        }
      }
    }
  }
}

/* Buttons */
.btn.btn-primary {
  &.ppp-button {
    box-shadow: none;
    border-radius: 5px;

    &-black,
    &-blue,
    &-red,
    &-gray {
      color: #fff;
      font-weight: 500;

      &:focus,
      &:hover,
      &:active {
        color: #fff;
        opacity: 0.95;
      }
    }

    &-black {
      background-color: #020810;

      &:focus,
      &:hover,
      &:active {
        background-color: #020810;
        color: #fff;
      }
    }

    &-blue {
      background-color: #004093;

      &:focus,
      &:hover,
      &:active {
        background-color: #004093;
        color: #fff;
      }
    }

    &-red {
      background-color: #f44336;

      &:focus,
      &:hover,
      &:active {
        background-color: #f44336;
        color: #fff;
      }
    }

    &-gray {
      background-color: #888;

      &:focus,
      &:hover,
      &:active {
        background-color: #888;
        color: #fff;
      }
    }

    &[disabled] {

      &:focus,
      &:hover,
      &:active {
        &.ppp-button-black {
          background-color: #020810;
          opacity: 0.65;
          cursor: not-allowed;
        }

        &.ppp-button-blue {
          background-color: #004093;
          opacity: 0.65;
          cursor: not-allowed;
        }

        &.ppp-button-red {
          background-color: #f44336;
          opacity: 0.65;
          cursor: not-allowed;
        }

        &.ppp-button-gray {
          background-color: #888;
          opacity: 0.65;
          cursor: not-allowed;
        }
      }
    }
  }
}

/* Step-by-step wizards */
.wizard-container {
  .wizard-navigation {
    background-color: #0b1d32;

    .nav-pills {
      background-color: inherit;
      padding: 0;

      li {
        .nav-link {
          color: #fff !important;

          &.active {
            background-color: #d5135b;
          }
        }

        &:focus,
        &:hover,
        &:active {
          .nav-link {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .card-body {
    padding: 15px;

    .tab-content {
      padding: 0;
    }
  }
}

/* Bootstrap blue tags system */
.bootstrap-tagsinput.info-badge .tag {
  background-color: #004093;
}

/* Forms */
.form-group {
  padding: 0 !important;
  margin-top: 10px !important;

  &.date-field {
    margin-top: 15px !important;
  }

  label {
    color: #000 !important;
    margin-bottom: 0;
  }

  .bmd-label-floating {
    line-height: 1 !important;
  }

  .form-control {
    height: 30px;
    padding: 0;
  }

  .form-control,
  .is-focused .form-control {
    background-image: linear-gradient(to top, #004093 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
  }

  &.is-focused,
  &.is-filled {
    .bmd-label-floating {
      top: -6px !important;
      font-size: 0.75rem !important;
    }
  }

  &.is-required {
    label:not(.force-no-required) {
      &:after {
        content: " *";
        color: red;
      }
    }
  }
}

/* Dropdown */
.dropdown.bootstrap-select {
  width: 100% !important;
  margin-top: 0;

  .btn.dropdown-toggle {
    padding: 6px;
    margin-top: 0;
    background-color: #ccc;
    color: #000;
  }

  .btn.dropdown-toggle.select-with-transition {
    height: 30px;
    padding: 0 !important;
    margin: 0 !important;
    background-image: linear-gradient(to top, #004093 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);

    .filter-option {
      padding-left: 0;
    }
  }

  .dropdown-item.active,
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus,
  .dropdown-menu a:hover,
  .dropdown-menu a:focus,
  .dropdown-menu a:active {
    box-shadow: none;
    background-color: #004093;
  }

  .dropdown-item.active {
    font-weight: 600;
  }
}



.form-group.has-danger {
  label {
    color: #f44336 !important;
  }

  &.form-radio-group {
    border: 1px solid #f44336;
  }
}

/* Safari hack */
a[type="button"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Fix for ngx-datatable on firefox*/
.ngx-datatable .datatable-row-center {
  backface-visibility: inherit !important;
}


@media screen and (min-width: 1367px) {

  /* Tables */
  .card {
    .card-body {
      .table-responsive {
        .ngx-datatable {
          .datatable-body-row {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.ck-editor__editable {
  min-height: 300px;
}
